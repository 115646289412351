import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Dialog, Divider, Grid, Stack, Box, Typography, Zoom, useMediaQuery } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// third-party
import { useDispatch, useSelector } from 'react-redux';

import React from 'react';

function PaymentNoticePage() {
  const theme = useTheme();
  const containerStyle = {
    // fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    lineHeight: '1.6'
  };

  const alertStyle = {
    backgroundColor: theme.palette.main,
    // borderLeft: '6px solid,
    // padding: '15px',
    marginBottom: '20px'
  };

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px'
  };

  const thStyle = {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: '8px',
    backgroundColor: '#f2f2f2'
  };

  const tdStyle = {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: '8px'
  };

  const sectionStyle = {
    marginBottom: '20px'
  };

  const noteStyle = {
    backgroundColor: '#f2f2f2',
    borderLeft: '6px solid' + theme.palette.primary.main,
    padding: '5px 15px',
    fontStyle: 'italic'
  };

  return (
    <div style={containerStyle}>
      <div style={alertStyle}>
        <h2 style={{margin: '0 0 10px 0'}}>Notice</h2>
        <p>Due to a systems migration, effective 3/31/25 and through 6/3/25, we will be unable to support self-service credit card payment processing via our online payment portal.</p>
      </div>

      <div style={sectionStyle}>
        <h2>ACH / WIRE Payment Details</h2>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={thStyle}>Payment Detail</th>
              <th style={thStyle}>Information</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={tdStyle}>Account Number</td>
              <td style={tdStyle}>4427171592</td>
            </tr>
            <tr>
              <td style={tdStyle}>Routing Number (ACH/EFT)</td>
              <td style={tdStyle}>111000012</td>
            </tr>
            <tr>
              <td style={tdStyle}>Wire Routing</td>
              <td style={tdStyle}>026009593</td>
            </tr>
            <tr>
              <td style={tdStyle}>Swift</td>
              <td style={tdStyle}>BOFAUS3N</td>
            </tr>
            <tr>
              <td style={tdStyle}>Account Name</td>
              <td style={tdStyle}>DISCUS DENTAL LLC</td>
            </tr>
            <tr>
              <td style={tdStyle}>Bank Name and Address</td>
              <td style={tdStyle}>Bank of America, 100 West 33rd Street, New York, NY 10001</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div style={sectionStyle}>
        <h2>Check Payment Details</h2>
        <p>BANK OF AMERICA<br />
        Discus Dental, LLC<br />
        PO Box 847632<br />
        Dallas, TX 75284-7632</p>
      </div>

      <div style={sectionStyle}>
        <h2>Additional Payment Options</h2>
        <p>You may also pay invoices via credit card by contacting:</p>
        <ul>
          <li>Your Philips Dental Professional Territory Business Manager</li>
          <li>Customer Service Department at 1-800-422-9448</li>
        </ul>
      </div>

      <div style={noteStyle}>
        <p>In early June we will introduce a new, improved self-service payment portal that will again enable you to pay Philips Dental Professional invoices online. We apologize for any frustration or inconvenience this may cause, and we look forward to supporting your future needs.</p>
      </div>
    </div>
  );
}

const Transition = forwardRef((props, ref) => <Zoom ref={ref} {...props} />);

// ==============================|| CHECKOUT CART - DISCOUNT COUPON CODE ||============================== //

const MessageDialog = ({ open, onClose}) => {
    const theme = useTheme();
    const current_lang = useSelector((state) => state.customization.locale);

    const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const navigate = useNavigate();


    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            maxWidth="md"
            sx={{ padding: 0 }}
            PaperProps={{
                elevation: 0,
                sx: {
                    border: "solid 1px gray"
                }
            }}
        >
            <MainCard
                sx={{
                    border: 'none',
                    margin: 0,
                    padding: 0,
                    overflow: 'auto',
                }}>
                    
                <PaymentNoticePage/>
                
                <Button style={{margin: '15px'}} onClick={onClose}> RETURN TO PROSHOP</Button>    
            </MainCard>
        </Dialog>
    );
};

MessageDialog.propTypes = {
    open: PropTypes.bool,
};

export default MessageDialog;
